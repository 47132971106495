import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import JoditEditorComponent from "../../components/editor/JoditEditorComponent";
import Loader from "../../components/loader/Loader";
import { get, imageBaseURL, patch } from "../../utils/fetchAPI";
import ConfirmStatusModal from "./ConfirmStatusModal";

const getImageUrl = (url, defaultImage) => {
  return url ? `${imageBaseURL}/${url}` : defaultImage;
};

const BulletinDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [bulletinData, setBulletinData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [bannerImages, setBannerImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [bulletinStatus, setBulletinStatus] = useState("pending");
  const [newStatusOptions, setNewStatusOptions] = useState([]);
  const [newStatus, setNewStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await get("/admin/clubs/focus-areas");
        if (response.status === 200) {
          setCategories(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        toast.error("Error fetching categories.");
      }
    };

    fetchCategories();
  }, []);

  const handleStatusClick = () => {
    let options = [];
    switch (bulletinStatus) {
      case "pending":
        options = ["published", "trash", "draft"];
        break;
      case "published":
        options = ["pending", "trash", "draft"];
        break;
      case "trash":
        options = ["pending", "published", "draft"];
        break;
      case "draft":
        options = ["pending", "published", "trash"];
        break;
      default:
        options = ["pending"];
    }
    setNewStatusOptions(options);
    setIsModalOpen(true);
  };

  const handleConfirmStatusChange = async () => {
    try {
      const formData = new FormData();
      formData.append("status", newStatus);

      const response = await patch(
        `/admin/bulletins/${bulletinData._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setBulletinStatus(newStatus);
        toast.success("Bulletin status updated!");
        setIsModalOpen(false);
      } else {
        toast.error("Status update failed.");
      }
    } catch (error) {
      console.error("Error updating bulletin status:", error);
      toast.error("Error updating bulletin status.");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const fetchBulletinData = async () => {
    try {
      const response = await get(`/admin/bulletins/${id}`);
      if (response.status === 200) {
        const data = response.data.data;
        setBulletinData(data);
        setBannerImages(data.banners || []);

        // Check if category and its _id exist before setting selectedCategory
        setSelectedCategory(data.category?._id || null);

        const status = data.status || "pending";
        if (["pending", "published", "trash", "draft"].includes(status)) {
          setBulletinStatus(status);
        } else {
          setBulletinStatus("pending");
        }
      } else {
        toast.error("Failed to fetch bulletin data.");
      }
    } catch (error) {
      console.error("Error fetching bulletin data:", error);
      toast.error("Error fetching bulletin data.");
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map((file) => file);
    setBannerImages((prevState) => [...prevState, ...newImages]);
  };

  useEffect(() => {
    fetchBulletinData();
  }, [id]);

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
  };

  const handleSaveChanges = async () => {
    try {
      const formData = new FormData();
      formData.append("title", bulletinData.title);
      formData.append("content", bulletinData.content);
      formData.append("category", selectedCategory);
      formData.append("status", bulletinStatus);

      bannerImages.forEach((banner) => {
        if (typeof banner !== "string") {
          formData.append("banners", banner);
        }
      });

      const response = await patch(`/admin/bulletins/${id}`, formData);
      if (response.status === 200) {
        setBulletinData(response.data.data);
        toast.success("Bulletin details updated successfully!");
        setIsEditing(false);
      } else {
        toast.error("Save failed.");
      }
    } catch (error) {
      toast.error("Error saving bulletin details.");
    }
  };

  if (!bulletinData)
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader />
      </div>
    );

  return (
    <div className="p-4 rounded-lg bg-white shadow-lg">
      <h2 className="text-lg font-bold mb-2">Bulletin Details</h2>
      <div className="flex justify-end mb-4">
        {isEditing ? (
          <>
            <button
              onClick={handleSaveChanges}
              className="px-4 py-2 bg-green-500 text-white text-sm font-semibold rounded-md mr-2"
            >
              Save
            </button>
            <button
              onClick={handleEditToggle}
              className="px-4 py-2 bg-gray-500 text-white text-sm font-semibold rounded-md"
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            onClick={handleEditToggle}
            className={`px-4 py-2 text-white text-sm font-semibold rounded-md ${
              bulletinData?.createdByModel !== "Admin"
                ? "bg-blue-400 opacity-50 cursor-not-allowed"
                : "bg-blue-500"
            }`}
            disabled={bulletinData?.createdByModel !== "Admin"}
            title={
              bulletinData?.createdByModel !== "Admin"
                ? "You cannot edit this"
                : ""
            }
          >
            Edit
          </button>
        )}
      </div>

      {/* Banners Section */}
      <Section title="Banner Images">
        <div className="flex flex-wrap -m-1">
          {bannerImages.map((banner, index) => (
            <div key={index} className="relative w-1/4 p-1">
              <img
                src={
                  typeof banner === "string"
                    ? getImageUrl(banner, "/default-image.png")
                    : URL.createObjectURL(banner)
                }
                alt={`Banner ${index + 1}`}
                className="w-full h-auto object-cover rounded-md"
              />
              {isEditing && (
                <button
                  onClick={() =>
                    setBannerImages(bannerImages.filter((_, i) => i !== index))
                  }
                  className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs"
                >
                  ✕
                </button>
              )}
            </div>
          ))}
        </div>

        {/* Message for image replacement */}
        {isEditing && (
          <p className="text-sm text-red-600 mt-2">
            [NB]: Choosing new images will replace all previous images.
          </p>
        )}

        {isEditing && (
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="mt-2"
          />
        )}
      </Section>

      <div className="grid grid-cols-4 gap-2 mb-4">
        {/* <Input
          label="Title"
          value={bulletinData.title}
          onChange={(e) =>
            setBulletinData({
              ...bulletinData,
              title: e.target.value,
            })
          }
          disabled={!isEditing}
        /> */}

        {/* Title as TextArea */}
        <div className="mb-2 col-span-2">
          <label className="text-sm font-medium text-primary">Title:</label>
          <textarea
            value={bulletinData.title}
            onChange={(e) =>
              setBulletinData({
                ...bulletinData,
                title: e.target.value,
              })
            }
            disabled={!isEditing}
            className={`w-full mt-1 p-2 border rounded-md text-sm resize-none h-10 ${
              !isEditing ? "bg-gray-100" : "bg-white"
            }`}
          />
        </div>

        {/* Category Select Dropdown */}
        <div className="mb-2 col-span-2">
          <label className="text-sm font-medium text-primary">Category:</label>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            disabled={!isEditing}
            className={`w-full mt-1 p-2 border rounded-md text-sm ${
              !isEditing ? "bg-gray-100" : "bg-white"
            } h-10`}
          >
            <option value="" disabled>
              Select a category
            </option>
            {categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <Input
          label="Club Name"
          value={`${bulletinData.club?.name || "Created by Admin (No Club)"} `}
          disabled
        />
        <Input
          label="Created By"
          value={
            bulletinData.createdBy
              ? `${bulletinData.createdBy.firstName} ${bulletinData.createdBy.lastName}`
              : "N/A"
          }
          disabled
        />
        <Input
          label="Creator's Email"
          value={bulletinData.createdBy?.email || "N/A"}
          disabled
        />
        <Input
          label="Creator's Phone"
          value={
            bulletinData.createdBy?.phone
              ? `${bulletinData.createdBy.phone.countryCode || ""}${
                  bulletinData.createdBy.phone.number || ""
                }`
              : "N/A"
          }
          disabled
        />
      </div>

      <Section title="Content">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Event Content <span className="text-red-500">*</span>
          </label>
          <JoditEditorComponent
            content={bulletinData.content}
            onChange={(newContent) =>
              setBulletinData({
                ...bulletinData,
                content: newContent,
              })
            }
            disabled={!isEditing}
          />
        </div>
      </Section>
      <Section title="Bulletin Status">
        <div className="flex items-center">
          <span className="text-sm font-medium mr-2">
            Current Bulletin Status:
          </span>
          <button
            onClick={handleStatusClick}
            className={`px-4 py-2 text-sm font-semibold rounded-md capitalize transition ${
              bulletinStatus === "published"
                ? "bg-green-500 text-white"
                : bulletinStatus === "trash"
                ? "bg-red-500 text-white"
                : bulletinStatus === "draft"
                ? "bg-blue-500 text-white"
                : "bg-yellow-300 text-black"
            } ${
              bulletinData?.createdByModel !== "Admin"
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            disabled={bulletinData?.createdByModel !== "Admin"}
            title={
              bulletinData?.createdByModel !== "Admin"
                ? "You cannot change the status"
                : "Click to change Bulletin status"
            }
          >
            {bulletinStatus === "published"
              ? "Published"
              : bulletinStatus === "trash"
              ? "Trash"
              : bulletinStatus === "draft"
              ? "Draft"
              : "Pending"}
          </button>
        </div>
        <p className="text-xs text-gray-600 mt-1">
          {bulletinData?.createdByModel !== "Admin"
            ? "Status change is not allowed for this bulletin."
            : "Click the button to change the bulletin status."}
        </p>
      </Section>

      {/* Confirm Status Modal */}
      <ConfirmStatusModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmStatusChange}
        currentStatus={bulletinStatus}
        newStatusOptions={newStatusOptions}
        setNewStatus={setNewStatus}
      />
    </div>
  );
};

const Input = ({ label, value, onChange, disabled }) => (
  <div className="mb-2">
    <label className="text-sm font-medium text-primary">{label}:</label>
    <input
      type="text"
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={`w-full mt-1 p-1 border rounded-md text-sm ${
        disabled ? "bg-gray-100" : "bg-white"
      }`}
    />
  </div>
);

const Section = ({ title, children }) => (
  <div className="mb-4">
    <h3 className="text-lg font-bold mb-2">{title}</h3>
    {children}
  </div>
);

export default BulletinDetail;
