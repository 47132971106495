import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import JoditEditorComponent from "../../components/editor/JoditEditorComponent";
import Loader from "../../components/loader/Loader";
import { del, get, imageBaseURL, patch } from "../../utils/fetchAPI";

const getImageUrl = (url, defaultImage) => {
  return url ? `${imageBaseURL}/${url}` : defaultImage;
};

const EditEvent = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [timezoneFilter, setTimezoneFilter] = useState("");

  const [form, setForm] = useState({
    title: "",
    subtitle: "",
    content: "",
    startDateTime: "",
    endDateTime: "",
    timezone: "",
    place: "",
    banner: null,
    thumbnail: null,
    status: false,
  });

  const timezoneOptions = moment.tz.names().map((tz) => ({
    value: tz,
    label: tz,
  }));

  const [imagePreviews, setImagePreviews] = useState({
    bannerPreview: null,
    thumbnailPreview: null,
    galleryPreviews: [],
  });

  const [existingGalleryImages, setExistingGalleryImages] = useState([]);
  const [newGalleryFiles, setNewGalleryFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEventData = async () => {
      setLoading(true);
      try {
        const response = await get(`/admin/events/${id}`);
        console.log("Response data:", response);

        if (response?.status === 200) {
          const data = response?.data?.data || [];

          const startDateTime = moment(data.startDateTime)
            .tz(data.timezone || "UTC")
            .format("YYYY-MM-DDTHH:mm");
          const endDateTime = moment(data.endDateTime)
            .tz(data.timezone || "UTC")
            .format("YYYY-MM-DDTHH:mm");

          setForm({
            title: data.title,
            subtitle: data.subtitle,
            content: data.content,
            startDateTime: startDateTime,
            endDateTime: endDateTime,
            timezone: data.timezone || "",
            place: data.place,
            banner: data.banner,
            thumbnail: data.thumbnail,
            status: data.status,
          });

          const galleryImages = data.gallery || [];

          setImagePreviews({
            bannerPreview: getImageUrl(
              data.banner,
              "path/to/default/banner.png"
            ),
            thumbnailPreview: getImageUrl(
              data.thumbnail,
              "path/to/default/thumbnail.png"
            ),
            galleryPreviews: galleryImages.map((img) =>
              getImageUrl(img, "path/to/default/gallery.png")
            ),
          });

          setExistingGalleryImages(galleryImages);
        } else {
          toast.error("Error fetching event data.");
        }
      } catch (error) {
        toast.error("Error fetching event data.");
        localStorage.removeItem("user");
        navigate("/login");
      } finally {
        setLoading(false);
      }
    };

    fetchEventData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (file, type) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (type === "banner") {
        setImagePreviews((prev) => ({
          ...prev,
          bannerPreview: reader.result,
        }));
        setForm((prev) => ({
          ...prev,
          banner: file,
        }));
      } else if (type === "thumbnail") {
        setImagePreviews((prev) => ({
          ...prev,
          thumbnailPreview: reader.result,
        }));
        setForm((prev) => ({
          ...prev,
          thumbnail: file,
        }));
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleGalleryImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length) {
      const newPreviews = files.map((file) => URL.createObjectURL(file));
      setNewGalleryFiles((prevState) => [...prevState, ...files]);
      setImagePreviews((prevState) => ({
        ...prevState,
        galleryPreviews: [...prevState.galleryPreviews, ...newPreviews],
      }));
    }
  };

  const removeGalleryImage = async (index) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        await del(
          `/admin/events/gallery/${id}?path=${existingGalleryImages[index]}`
        );

        const updatedGalleryPreviews = [...imagePreviews.galleryPreviews];
        updatedGalleryPreviews.splice(index, 1);
        setImagePreviews((prev) => ({
          ...prev,
          galleryPreviews: updatedGalleryPreviews,
        }));

        const updatedExistingGalleryImages = [...existingGalleryImages];
        updatedExistingGalleryImages.splice(index, 1);
        setExistingGalleryImages(updatedExistingGalleryImages);

        Swal.fire("Deleted!", "Your image has been deleted.", "success");
      } catch (error) {
        console.error("Error deleting image:", error);
        Swal.fire("Error!", "There was an issue deleting the image.", "error");
      }
    }
  };
  const handleContentChange = (content) => {
    setForm((prevState) => ({
      ...prevState,
      content,
    }));
  };

  const handleStatusChange = () => {
    setForm((prevState) => ({
      ...prevState,
      status: !prevState.status,
    }));
  };

  const handleTimezoneFilterChange = (e) => {
    setTimezoneFilter(e.target.value);
  };

  const handleTimeZoneChange = (selectedOption) => {
    setForm((prevState) => ({
      ...prevState,
      timezone: selectedOption?.value || "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting

    const formData = new FormData();

    const startDateTimeUTC = moment
      .tz(form.startDateTime, form.timezone)
      .utc()
      .format();
    const endDateTimeUTC = moment
      .tz(form.endDateTime, form.timezone)
      .utc()
      .format();

    formData.append("startDateTime", startDateTimeUTC);
    formData.append("endDateTime", endDateTimeUTC);

    Object.keys(form).forEach((key) => {
      if (
        key !== "startDateTime" &&
        key !== "endDateTime" &&
        key !== "gallery"
      ) {
        formData.append(key, form[key]);
      }
    });

    try {
      const response = await patch(`/admin/events/${id}`, formData);
      console.log("response: ", response.data);

      if (newGalleryFiles.length > 0) {
        const uploadPromises = newGalleryFiles.map((file) => {
          const galleryData = new FormData();
          galleryData.append("gallery", file);

          return patch(`/admin/events/gallery/${id}`, galleryData);
        });

        const galleryResponses = await Promise.all(uploadPromises);

        if (galleryResponses.every((res) => res.status === 200)) {
          toast.success("Event and gallery images updated successfully!");
        } else {
          toast.error("Error updating one or more gallery images.");
          throw new Error("Gallery image update failed");
        }
      } else {
        // toast.success("Event updated successfully!");
      }

      if (response?.data?.statusCode === 200) {
        toast.success("Event updated successfully!");

        navigate(`/events/edit/${id}`);
      } else {
        toast.error(
          response.error || `Error updating event. Please try again.`
        );
      }
      navigate(`/events/edit/${id}`);
    } catch (error) {
      console.error("Error updating event:", error);
      const errorMessage =
        error.response?.data?.error ||
        "Error updating event. Please try again.";
      toast.error(errorMessage);
    } finally {
      setLoading(false); // Set loading to false after submission
    }
  };

  // Show loader while loading
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <section className="p-1">
      <div className="py-1">
        {/* Header */}
        <div className="flex justify-start items-center py-1">
          <h2 className="text-2xl font-bold">Edit Event</h2>
        </div>

        {/* Form */}
        <div className="bg-white p-5 rounded shadow-md">
          <form onSubmit={handleSubmit}>
            {/* Title, Subtitle, Start and End Date/Time, Time Zone */}
            <div className="mb-4 flex">
              <div className="flex-1 mr-2">
                <label className="block text-sm font-medium text-gray-700">
                  Title <span className="text-red-500">*</span>
                </label>
                <textarea
                  type="text"
                  name="title"
                  value={form.title}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded"
                  required
                />
              </div>
              <div className="flex-1 mx-2">
                <label className="block text-sm font-medium text-gray-700">
                  Subtitle
                </label>
                <textarea
                  name="subtitle"
                  value={form.subtitle}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded"
                />
              </div>
              <div className="flex-1 ml-2">
                <label className="block text-sm font-medium text-gray-700">
                  Start Date & Time
                </label>
                <input
                  type="datetime-local"
                  name="startDateTime"
                  value={form.startDateTime}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded"
                  required
                />
              </div>
              <div className="flex-1 ml-2">
                <label className="block text-sm font-medium text-gray-700">
                  End Date & Time
                </label>
                <input
                  type="datetime-local"
                  name="endDateTime"
                  value={form.endDateTime}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded"
                  required
                />
              </div>
            </div>
            <div className="mb-4 flex">
              <div className="flex-1 mr-2">
                <label className="block text-sm font-medium text-gray-700">
                  Time Zone <span className="text-red-500">*</span>
                </label>
                <Select
                  options={timezoneOptions}
                  value={timezoneOptions.find(
                    (option) => option.value === form.timezone
                  )}
                  onChange={handleTimeZoneChange}
                  isSearchable={true}
                  placeholder="Select Time Zone"
                  className="w-full mt-2"
                />
              </div>
              <div className="flex-1 ml-2">
                <label className="block text-sm font-medium text-gray-700">
                  Place
                </label>
                <input
                  type="text"
                  name="place"
                  value={form.place}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded"
                />
              </div>
            </div>
            {/* Banner, Thumbnail */}
            <div className="mb-4 grid grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label className="block text-sm font-medium text-gray-700">
                  Banner Image <span className="text-red-500">*</span>
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleFileChange(e.target.files[0], "banner")
                  }
                  className="w-full mt-1 p-2 border border-gray-300 rounded"
                />
                {imagePreviews.bannerPreview && (
                  <img
                    src={imagePreviews.bannerPreview}
                    alt="Banner Preview"
                    className="mt-2 h-64 w-auto object-contain"
                  />
                )}
              </div>

              <div className="flex flex-col">
                <label className="block text-sm font-medium text-gray-700">
                  Thumbnail Image <span className="text-red-500">*</span>
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleFileChange(e.target.files[0], "thumbnail")
                  }
                  className="w-full mt-1 p-2 border border-gray-300 rounded"
                />
                {imagePreviews.thumbnailPreview && (
                  <img
                    src={imagePreviews.thumbnailPreview}
                    alt="Thumbnail Preview"
                    className="mt-2 h-64 w-auto object-contain"
                  />
                )}
              </div>
            </div>

            {/* Content */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Event Content <span className="text-red-500">*</span>
              </label>
              <JoditEditorComponent
                content={form.content}
                onChange={handleContentChange}
              />
            </div>

            {/* Add New Gallery Images */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Add New Gallery Images
              </label>
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={handleGalleryImageChange}
                className="w-full mt-1 p-2 border border-gray-300 rounded"
              />
            </div>

            {/* Gallery Previews */}
            <div className="mb-4">
              <div className="flex flex-wrap">
                {imagePreviews.galleryPreviews.map((preview, index) => (
                  <div key={index} className="relative mr-2 mb-2">
                    <img
                      src={preview}
                      alt={`Gallery Preview ${index + 1}`}
                      className="h-32 w-auto object-cover rounded"
                    />
                    <button
                      type="button"
                      className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                      onClick={() => removeGalleryImage(index)}
                    >
                      <span className="text-xs">X</span>
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Status */}
            <div className="mb-4 flex justify-between items-center">
              <label className="block text-sm font-medium text-gray-700">
                {/* Publish Event */}
              </label>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={form.status}
                  onChange={handleStatusChange}
                  className="hidden"
                  id="statusToggle"
                />
                <label
                  htmlFor="statusToggle"
                  className={`flex items-center cursor-pointer w-16 h-8 bg-gray-300 rounded-full transition duration-300 ease-in-out 
      ${form.status ? "bg-green-500" : "bg-red-500"}`}
                >
                  <span
                    className={`bg-white w-7 h-7 rounded-full shadow-md transform transition duration-300 ease-in-out 
        ${form.status ? "translate-x-8" : "translate-x-0"}`}
                  ></span>
                </label>
                <span className="ml-3 text-sm font-medium text-gray-700">
                  {form.status ? "Active" : "Inactive"}
                </span>
              </div>
            </div>

            {/* Submit Button */}
            <div className="mt-6">
              <button
                type="submit"
                className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Save Event
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default EditEvent;
