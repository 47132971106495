import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { getWithParams } from "../../utils/fetchAPI";
import ClubTable from "./clubTable";

const Clubs = () => {
  const [clubs, setClubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState("");

  const navigate = useNavigate();

  const fetchClubs = async (page, limit, searchTerm = "", status = "") => {
    setLoading(true);
    try {
      const params = { page, limit };
      if (searchTerm) params.search = searchTerm;
      if (status) params.clubStatus = status;

      const response = await getWithParams("admin/clubs", params);

      if (response?.status === 200) {
        setClubs(response?.data?.data?.docs || []);
        setTotalRows(response?.data?.data?.totalDocs);
      } else {
        setClubs([]);
      }
    } catch (err) {
      setError("Failed to fetch clubs");
      localStorage.removeItem("user");
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("user");
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }

    fetchClubs(page, limit, searchQuery, status);
  }, [page, limit, searchQuery, status, navigate]);

  const handleSearch = () => {
    setPage(1);
    setSearchQuery(search);
  };

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "user" && !e.newValue) {
        navigate("/login");
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="w-full">
      {loading ? (
        <div className="flex items-center justify-center min-h-screen">
          <Loader />
        </div>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <ClubTable
          clubs={clubs}
          totalRows={totalRows}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          search={search}
          setSearch={setSearch}
          handleSearch={handleSearch}
          status={status}
          setStatus={setStatus}
        />
      )}
    </div>
  );
};

export default Clubs;
