// userTable.jsx
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { FaEye } from "react-icons/fa";
import { put } from "../../utils/fetchAPI";
import ConfirmStatusModal from "./ConfirmStatusModal";
import EditStatusModal from "./EditStatusModal";

const UserTable = ({
  users: initialUsers,
  totalRows,
  page,
  limit,
  setPage,
  setLimit,
  search,
  setSearch,
  handleSearch,
  status,
  setStatus,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  // const [statusToUpdate, setStatusToUpdate] = useState(null);
  const [loadingStatusId, setLoadingStatusId] = useState(null);
  const [users, setUsers] = useState(initialUsers);
  const [newStatusOptions, setNewStatusOptions] = useState([]);
  const [newStatus, setNewStatus] = useState("");

  const handleStatusChange = (userId, currentStatus) => {
    if (currentStatus === "incomplete") {
      alert(
        "Users with 'incomplete' status cannot be changed to any other status."
      );
      return;
    }

    let options = [];
    if (currentStatus === "active") {
      options = ["inactive", "suspended"];
    } else if (currentStatus === "inactive") {
      options = ["active", "suspended"];
    } else if (currentStatus === "suspended") {
      options = ["active", "inactive"];
    }

    setSelectedUser({
      _id: userId,
      currentStatus,
    });
    setNewStatusOptions(options);
    setConfirmModalOpen(true);
  };

  const handleStatusUpdate = async () => {
    const { _id: userId } = selectedUser;

    if (!newStatus) {
      alert("Please select a new status.");
      return;
    }

    setLoadingStatusId(userId);

    try {
      const response = await put(`/admin/users/update-status/${userId}`, {
        status: newStatus,
      });

      if (response?.status === 200) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user._id === userId ? { ...user, status: newStatus } : user
          )
        );
        setConfirmModalOpen(false);
      }
    } catch (error) {
      console.error("Error updating status", error);
      alert("Failed to update status. Please try again.");
    } finally {
      setLoadingStatusId(null);
    }
  };

  const columns = [
    {
      name: "SL",
      cell: (row, index) => (page - 1) * limit + index + 1,
      sortable: false,
    },
    {
      name: "Club Member ID",
      selector: (row) => row.memberCode || "N/A",
      sortable: true,
    },
    {
      name: "Full Name",
      selector: (row) => `${row.firstName} ${row.lastName}`,
      sortable: true,
    },
    // {
    //   name: "Username",
    //   selector: (row) => row.username,
    //   sortable: true,
    // },
    {
      name: "Phone",
      selector: (row) =>
        row.phone ? `${row.phone.countryCode}${row.phone.number}` : "N/A",
      sortable: true,
      width: "250px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "250px",
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
    },
    // {
    //   name: "Online Status",
    //   cell: (row) => (
    //     <div className="flex justify-center items-center">
    //       <FaCircle
    //         className={`w-4 h-4 ${
    //           row.isOnline ? "text-green-500" : "text-red-500"
    //         }`}
    //       />
    //       <span className="ml-2 text-sm text-primary">
    //         {row.isOnline ? "Online" : "Offline"}
    //       </span>
    //     </div>
    //   ),
    //   sortable: true,
    // },
    {
      name: "Status",
      cell: (row) => (
        <div className="flex justify-center">
          {row.status === "incomplete" ? (
            <span className="bg-slate-300 px-3 py-1 text-sm rounded-md">
              Incomplete
            </span>
          ) : (
            <>
              <input
                type="checkbox"
                checked={row.status === "active"}
                onChange={() =>
                  handleStatusChange(
                    row._id,
                    row.status,
                    row.status === "active"
                      ? "inactive"
                      : row.status === "inactive"
                      ? "suspended"
                      : row.status === "suspended"
                      ? "incomplete"
                      : "active"
                  )
                }
                className="hidden"
                id={`statusToggle-${row._id}`}
              />
              <label
                htmlFor={`statusToggle-${row._id}`}
                className={`flex justify-center cursor-pointer w-fit px-3 py-1 text-sm rounded-md capitalize text-center transition duration-300 ease-in-out 
            ${
              row.status === "active"
                ? "bg-green-200"
                : row.status === "inactive"
                ? "bg-red-200"
                : row.status === "suspended"
                ? "bg-orange-300"
                : "bg-gray-200"
            }`}
              >
                {row.status}
              </label>
            </>
          )}
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          onClick={() => {
            setSelectedUser(row);
            setIsModalOpen(true);
          }}
          className="text-blue-500 hover:text-blue-700"
          aria-label="Edit status"
        >
          <FaEye className="text-xl" />
        </button>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#f9fafb",
        fontWeight: "bold",
      },
    },
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1);
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
      <DataTable
        columns={columns}
        data={users}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={page}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[5, 10, 20, 50]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        highlightOnHover
        responsive
        customStyles={customStyles}
        subHeader
        subHeaderComponent={
          <div className="flex justify-between items-center w-full">
            <h1 className="text-xl font-bold">Users</h1>
            <div className="flex items-center space-x-2">
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="p-2 border rounded"
                aria-label="Filter by status"
              >
                <option value="">Select Status</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
                <option value="incomplete">Incomplete</option>
                <option value="suspended">Suspended</option>
              </select>

              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search email..."
                className="p-2 border rounded"
                aria-label="Search bulletins"
              />
              <button
                onClick={handleSearch}
                className="ml-2 p-2 bg-blue-500 text-white rounded"
              >
                Search
              </button>
            </div>
          </div>
        }
      />

      {/* Edit Status Modal */}
      <EditStatusModal
        user={selectedUser}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onStatusUpdate={handleStatusUpdate}
      />

      {/* Confirm Status Change Modal */}
      <ConfirmStatusModal
        isOpen={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={handleStatusUpdate}
        currentStatus={selectedUser?.currentStatus}
        newStatusOptions={newStatusOptions}
        setNewStatus={setNewStatus}
      />
    </div>
  );
};

export default UserTable;
