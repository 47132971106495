import React from "react";
import loader from "../Loader.gif";

const Loader = () => {
  return (
    <div style={{ height: "200px", width: "200px" }}>
      <img
        src={loader}
        alt="Loading..."
        style={{ width: "100%", height: "auto" }}
      />
    </div>
  );
};

export default Loader;
