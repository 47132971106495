import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { getWithParams } from "../../utils/fetchAPI";
import BulletinTable from "./bulletinTable";

const Bulletins = () => {
  const [bulletins, setBulletins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState("");
  const [createdByModel, setCreatedByModel] = useState("");

  const navigate = useNavigate();

  const fetchBulletins = async (
    page,
    limit,
    searchTerm = "",
    status = "",
    createdByModel = ""
  ) => {
    setLoading(true);
    try {
      const params = { page, limit };
      if (searchTerm) params.search = searchTerm;
      if (status) params.status = status;
      if (createdByModel) params.createdByModel = createdByModel;

      const response = await getWithParams("admin/bulletins", params);
      console.log("API Response:", response);

      if (response?.status === 200) {
        const { docs, totalDocs } = response?.data?.data;
        console.log("Filtered docs:", docs);
        console.log("Filtered total rows:", totalDocs);

        setBulletins(docs || []);
        setTotalRows(totalDocs || 0);
      } else {
        setBulletins([]);
        setTotalRows(0);
      }
    } catch (err) {
      setError("Failed to fetch bulletins");
      localStorage.removeItem("user");
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("user");
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }

    fetchBulletins(page, limit, searchQuery, status, createdByModel);
  }, [page, limit, searchQuery, status, createdByModel, navigate]);

  const handleSearch = () => {
    setPage(1);
    setSearchQuery(search);
  };

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "user" && !e.newValue) {
        navigate("/login");
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate]);

  return (
    <div className="w-full">
      {loading ? (
        <div className="flex items-center justify-center min-h-screen">
          <Loader />
        </div>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <BulletinTable
          bulletins={bulletins}
          totalRows={totalRows}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          search={search}
          setSearch={setSearch}
          handleSearch={handleSearch}
          status={status}
          setStatus={setStatus}
          createdByModel={createdByModel}
          setCreatedByModel={setCreatedByModel}
        />
      )}
    </div>
  );
};

export default Bulletins;
