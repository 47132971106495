// clubTable.jsx
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { FaEye } from "react-icons/fa";
// import ConfirmStatusModal from "./ConfirmStatusModal";
// import EditStatusModal from "./EditStatusModal";
import { useNavigate } from "react-router-dom";

const ClubTable = ({
  clubs: initialClubs,
  totalRows,
  page,
  limit,
  setPage,
  setLimit,
  search,
  setSearch,
  handleSearch,
  status,
  setStatus,
}) => {
  // Removed unused isModalOpen state
  // Removed unused selectedClub state
  // Removed unused confirmModalOpen state
  // Removed unused loadingStatusId state
  const [clubs] = useState(initialClubs);
  const navigate = useNavigate();
  // Removed unused newStatusOptions state
  // Removed unused setNewStatus state
  // Removed unused newStatus state

  const handleStatusChange = (clubId, currentStatus, newStatus) => {
    // Removed setting selectedClub as it is unused
    // Removed setting confirmModalOpen as it is unused
  };

  //   const handleStatusUpdate = async () => {
  //     const { _id: clubId, newStatus } = selectedClub;
  //     setLoadingStatusId(clubId);

  //     try {
  //       const response = await put(`/admin/clubs/update-status/${clubId}`, {
  //         status: newStatus,
  //       });

  //       if (response?.status === 200) {
  //         setClubs((prevClubs) =>
  //           prevClubs.map((club) =>
  //             club._id === clubId
  //               ? { ...club, isValid: newStatus === "valid" }
  //               : club
  //           )
  //         );
  //         setConfirmModalOpen(false);
  //       }
  //     } catch (error) {
  //       console.error("Error updating status", error);
  //       alert("Failed to update status. Please try again.");
  //     } finally {
  //       setLoadingStatusId(null);
  //     }
  //   };

  const columns = [
    {
      name: "SL",
      cell: (row, index) => (page - 1) * limit + index + 1,
      sortable: false,
      width: "80px",
    },
    {
      name: "Club ID",
      selector: (row) => row.clubCode || "N/A",
      sortable: true,
    },
    {
      name: "Club Name",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
      width: "250px",
    },
    {
      name: "Phone",
      selector: (row) =>
        row.phone ? `${row.phone.countryCode}${row.phone.number}` : "N/A",
      sortable: true,
      width: "150px",
    },
    {
      name: "Email",
      selector: (row) => row.email || "N/A",
      sortable: true,
      width: "250px",
    },
    // {
    //   name: "Address",
    //   selector: (row) => row.address || "N/A",
    //   sortable: true,
    //   wrap: true,
    // },
    {
      name: "Gender",
      selector: (row) => row.clubMemberGender || "N/A",
      sortable: true,
    },
    {
      name: "Club Status",
      cell: (row) => (
        <div className="flex justify-center">
          <input
            type="checkbox"
            checked={row.clubStatus === "approved"}
            onChange={() =>
              handleStatusChange(
                row._id,
                row.clubStatus,
                row.clubStatus === "approved"
                  ? "rejected"
                  : row.clubStatus === "rejected"
                  ? "pending"
                  : row.clubStatus === "pending"
                  ? "incomplete"
                  : row.clubStatus === "incomplete"
                  ? "approved"
                  : "approved"
              )
            }
            className="hidden"
            id={`statusToggle-${row._id}`}
          />
          <label
            htmlFor={`statusToggle-${row._id}`}
            className={`flex justify-center cursor-pointer w-fit px-3 py-1 text-sm rounded-md capitalize text-center transition duration-300 ease-in-out 
        ${
          row.clubStatus === "approved"
            ? "bg-green-200"
            : row.clubStatus === "rejected"
            ? "bg-red-200"
            : row.clubStatus === "pending"
            ? "bg-slate-300"
            : row.clubStatus === "processing"
            ? "bg-yellow-200"
            : "bg-gray-200"
        }`}
          >
            {row.clubStatus === "approved"
              ? "Approved"
              : row.clubStatus === "rejected"
              ? "Rejected"
              : row.clubStatus === "pending"
              ? "Pending"
              : row.clubStatus === "processing"
              ? "Processing"
              : "Pending"}
          </label>
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          onClick={() => {
            navigate(`/clubs/${row._id}`);
          }}
          className="text-blue-500 hover:text-blue-700"
          aria-label="View club details"
        >
          <FaEye className="text-xl" />
        </button>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#f9fafb",
        fontWeight: "bold",
      },
    },
  };

  const customPaginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: false,
    selectAllRowsItem: false,
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1);
  };

  // Removed unused renderPageNumbers function

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
      <DataTable
        columns={columns}
        data={clubs}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={page}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[5, 10, 20, 50]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        highlightOnHover
        responsive
        customStyles={customStyles}
        paginationComponentOptions={customPaginationOptions}
        subHeader
        subHeaderComponent={
          <div className="flex justify-between items-center w-full">
            <h1 className="text-xl font-bold">Clubs</h1>
            <div className="flex items-center space-x-2">
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="p-2 border rounded"
                aria-label="Filter by status"
              >
                <option value="">Select Status</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
                <option value="pending">Pending</option>
                <option value="processing">Processing</option>
              </select>
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search clubs..."
                className="p-2 border rounded"
                aria-label="Search bulletins"
              />
              <button
                onClick={handleSearch}
                className="ml-2 p-2 bg-blue-500 text-white rounded"
              >
                Search
              </button>
            </div>
          </div>
        }
      />

      {/* Edit Club Modal */}
      {/* <EditStatusModal
        club={selectedClub}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onStatusUpdate={handleStatusUpdate}
      /> */}

      {/* Confirm Status Change Modal */}
      {/* <ConfirmStatusModal
        isOpen={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={handleStatusUpdate}
        currentStatus={selectedClub?.currentStatus}
        newStatus={selectedClub?.newStatus}
      /> */}
    </div>
  );
};

export default ClubTable;
