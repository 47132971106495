import { DateTime } from "luxon";
import React, { useEffect } from "react";
import Modal from "react-modal";
import JoditEditorComponent from "../../components/editor/JoditEditorComponent";
import { imageBaseURL } from "../../utils/fetchAPI";

Modal.setAppElement("#root");

const getImageUrl = (url, defaultImage) => {
  return url ? `${imageBaseURL}/${url}` : defaultImage;
};

const defaultEvent = {
  title: "",
  subtitle: "",
  startDateTime: new Date(),
  endDateTime: new Date(),
  banner: null,
  thumbnail: null,
  content: "",
  gallery: [],
  place: "",
  status: false,
  timezone: "",
};

const formatDateTime = (dateTime, timezone) => {
  const dt = DateTime.fromISO(dateTime).setZone(timezone);
  const formattedDate = dt.toFormat("dd/MM/yyyy");
  const formattedTime = dt.toFormat("hh:mm a");

  return `${formattedDate} (${formattedTime})`;
};

const ViewEventModal = ({ event = defaultEvent, isOpen, onClose }) => {
  useEffect(() => {
    console.log(event);
  }, [event]);

  if (!event) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center m-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm"
    >
      <div className="relative w-full max-w-[1350px] max-h-[600px] overflow-auto rounded-lg bg-white shadow-lg p-4">
        <div className="flex items-start justify-between">
          <h2 className="text-lg font-semibold mb-4 text-gray-800">
            Event Details
          </h2>
          <button
            onClick={onClose}
            className="relative h-8 w-8 rounded-lg text-gray-500 transition-all hover:bg-gray-200"
            type="button"
          >
            <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                className="h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
        </div>

        {/* Row 1: Title, Subtitle, Start Date & Time, End Date & Time */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-3 mb-4">
          <div>
            <h4 className="text-sm font-medium text-gray-700">Title</h4>
            <p className="mt-1 p-2 border border-gray-300 rounded bg-gray-100 text-sm">
              {event.title}
            </p>
          </div>
          <div>
            <h4 className="text-sm font-medium text-gray-700">Subtitle</h4>
            <p className="mt-1 p-2 border border-gray-300 rounded bg-gray-100 text-sm">
              {event.subtitle}
            </p>
          </div>
          <div>
            <h4 className="text-sm font-medium text-gray-700">
              Start Date & Time
            </h4>
            <p className="mt-1 p-2 border border-gray-300 rounded bg-gray-100 text-sm">
              {formatDateTime(event.startDateTime, event.timezone)}
            </p>
          </div>
          <div>
            <h4 className="text-sm font-medium text-gray-700">
              End Date & Time
            </h4>
            <p className="mt-1 p-2 border border-gray-300 rounded bg-gray-100 text-sm">
              {formatDateTime(event.endDateTime, event.timezone)}
            </p>
          </div>
        </div>

        {/* Row 2: Time Zone, Place */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-4">
          <div>
            <h4 className="text-sm font-medium text-gray-700">Timezone</h4>
            <p className="mt-1 p-2 border border-gray-300 rounded bg-gray-100 text-sm">
              {event.timezone}
            </p>
          </div>
          <div>
            <h4 className="text-sm font-medium text-gray-700">Place</h4>
            <p className="mt-1 p-2 border border-gray-300 rounded bg-gray-100 text-sm">
              {event.place}
            </p>
          </div>
        </div>

        {/* Row 3: Banner Image, Thumbnail Image */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-4">
          <div>
            <h4 className="text-sm font-medium text-gray-700">Banner Image</h4>
            {event.banner && (
              <img
                src={getImageUrl(event.banner)}
                alt="Banner Preview"
                className="mt-2 w-full h-auto object-contain rounded"
                style={{ maxHeight: "250px", maxWidth: "100%" }}
              />
            )}
          </div>
          <div>
            <h4 className="text-sm font-medium text-gray-700">
              Thumbnail Image
            </h4>
            {event.thumbnail && (
              <img
                src={getImageUrl(event.thumbnail)}
                alt="Thumbnail Preview"
                className="mt-2 w-full h-auto object-contain rounded"
                style={{ maxHeight: "250px", maxWidth: "100%" }}
              />
            )}
          </div>
        </div>

        {/* Row 4: Content */}
        <div className="mb-4">
          <h4 className="text-sm font-medium text-gray-700">Content</h4>
          <div className="h-auto">
            <JoditEditorComponent content={event.content} readOnly={true} />
          </div>
        </div>

        {/* Row 5: Gallery Images */}
        <div className="mb-4">
          <h4 className="text-sm font-medium text-gray-700">Gallery Images</h4>
          <div className="flex flex-wrap">
            {(event.gallery || []).map((img, index) => (
              <div key={index} className="relative mr-2 mb-2">
                {" "}
                {/* Adjusted spacing between images */}
                <img
                  src={getImageUrl(img)}
                  alt={`Gallery Preview ${index + 1}`}
                  className="h-32 w-auto object-cover rounded"
                />
              </div>
            ))}
          </div>
        </div>

        {/* Last Row: Status */}
        <div className="mb-4 flex justify-between items-center">
          <label className="block text-sm font-medium text-gray-700">
            {/* Status */}
          </label>
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={event.status}
              className="hidden"
              id="statusToggleDisplay"
              disabled
            />
            <label
              htmlFor="statusToggleDisplay"
              className={`flex items-center cursor-not-allowed w-16 h-8 bg-gray-300 rounded-full transition duration-300 ease-in-out 
              ${event.status ? "bg-green-500" : "bg-red-500"} ${
                event.status ? "opacity-100" : "opacity-50"
              }`}
            >
              <span
                className={`bg-white w-7 h-7 rounded-full shadow-md transform transition duration-300 ease-in-out 
                ${event.status ? "translate-x-8" : "translate-x-0"}`}
              ></span>
            </label>
            <span className="ml-2 text-sm font-medium text-gray-700 opacity-70">
              {event.status ? "Active" : "Inactive"}
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewEventModal;
