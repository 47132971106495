import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/loader/Loader";
import { getWithParams } from "../../../utils/fetchAPI";
import ClubDeclarationsTable from "./clubDeclarationsTable";

const ClubDeclarations = () => {
  const [clubDeclarations, setClubDeclarations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const navigate = useNavigate();

  const fetchClubDeclarations = async (page, limit) => {
    setLoading(true);
    try {
      const response = await getWithParams("admin/club-declarations", {
        page,
        limit,
      });

      if (response?.status === 200) {
        setClubDeclarations(response?.data?.data?.docs || []);
        setTotalRows(response?.data?.data?.totalDocs);
      } else {
        setClubDeclarations([]);
      }
    } catch (err) {
      setError("Failed to fetch club declarations");
      localStorage.removeItem("user");
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("user");
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }

    fetchClubDeclarations(page, limit);
  }, [page, limit, navigate]);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "user" && !e.newValue) {
        navigate("/login");
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="w-full">
      <ClubDeclarationsTable
        clubDeclarations={clubDeclarations}
        totalRows={totalRows}
        setTotalRows={setTotalRows}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
        fetchClubDeclarations={fetchClubDeclarations} // Passing the function as prop
      />
    </div>
  );
};

export default ClubDeclarations;
