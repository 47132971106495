import React, { useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import {
  FaArrowLeft,
  FaBullhorn,
  FaCalendarAlt,
  FaCog,
  FaExpand,
  FaHome,
  FaKey,
  FaUniversity,
  FaUser,
  FaUsers,
} from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { get, imageBaseURL } from "../../utils/fetchAPI";
import { LocalStorageService } from "../../utils/local-storage";

const getImageUrl = (url, defaultImage) =>
  url ? `${imageBaseURL}/${url}` : defaultImage;

const NavBar = ({ isCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [adminName, setAdminName] = useState("");
  const [adminPhoto, setAdminPhoto] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAdminDetails = async () => {
      try {
        const response = await get("/admin/admins/get-details");

        if (response.data.success) {
          setAdminName(response.data.data.firstName);
          setAdminPhoto(response.data.data.profilePhoto);
        } else {
          console.error("Failed to fetch admin details");
        }
      } catch (error) {
        console.error("Error fetching admin details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAdminDetails();
  }, []);

  const handleLogout = () => {
    LocalStorageService.clear();
    navigate("/login", { replace: true });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const links = [
    { to: "/dashboard", icon: <FaHome />, text: "Dashboard" },
    { to: "/users", icon: <FaUsers />, text: "Users" },
    { to: "/admins", icon: <FaUsers />, text: "Admins" }, // Consider using a different icon
    { to: "/events/view", icon: <FaCalendarAlt />, text: "Events" },
    { to: "/events/create", icon: <FaCalendarAlt />, text: "Create Event" },
    { to: "/events/edit/:id", icon: <FaCalendarAlt />, text: "Edit Event" },
    { to: "/clubs", icon: <FaUniversity />, text: "Clubs" },
    { to: "/clubs/:id", icon: <FaUniversity />, text: "Club Detail" },
    { to: "/bulletins", icon: <FaBullhorn />, text: "Bulletins" },
    { to: "/bulletins/create", icon: <FaBullhorn />, text: "Create Bulletin" },
    { to: "/bulletins/:id", icon: <FaBullhorn />, text: "Bulletin Detail" },
    { to: "/settings", icon: <FaCog />, text: "Settings" },
    { to: "/settings/focus-areas", icon: <FaCog />, text: "Focus Areas" },
    {
      to: "/settings/club-declarations",
      icon: <FaCog />,
      text: "Club Declarations",
    },
    { to: "/settings/club-spirits", icon: <FaCog />, text: "Club Spirits" },
    {
      to: "/settings/club-member-interest-reasons",
      icon: <FaCog />,
      text: "Club Member Interest Reasons",
    },
    {
      to: "/settings/club-member-professions",
      icon: <FaCog />,
      text: "Club Member Professions",
    },
    { to: "/settings/city", icon: <FaCog />, text: "Geo Cities" },
    { to: "/settings/state", icon: <FaCog />, text: "Geo States" },
    { to: "/profile", icon: <FaUser />, text: "My Profile" },
    { to: "/change-password", icon: <FaKey />, text: "Change Password" },
  ];

  const activeLink = links.find(
    (link) =>
      link.to === location.pathname ||
      (link.to.includes(":id") &&
        location.pathname.startsWith(link.to.split(":id")[0]))
  );

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (loading) return <div>Loading...</div>;

  return (
    <nav
      className={`fixed bg-lightSecondary text-white p-2 shadow-md z-10 flex items-center justify-between transition-all duration-300 ${
        isCollapsed ? "w-[calc(100%-4rem)] ml-16" : "w-[calc(100%-16rem)] ml-64"
      }`}
      style={{ height: "3rem" }}
    >
      <div className="flex items-center space-x-4 ml-2">
        {activeLink && (
          <span className="flex items-center text-lg font-semibold">
            {activeLink.icon}
            <span className="ml-2">{activeLink.text}</span>
          </span>
        )}
        {location.pathname !== "/dashboard" && (
          <button
            onClick={handleBack}
            className="flex items-center text-sm p-2 hover:bg-gray-700 rounded"
          >
            <FaArrowLeft className="mr-1" />
            Back
          </button>
        )}
      </div>
      <div className="flex items-center space-x-4 mr-2">
        <button
          onClick={toggleFullScreen}
          className="text-white p-2 hover:bg-gray-700 rounded-full"
        >
          <FaExpand />
        </button>
        <div
          className="relative"
          ref={dropdownRef}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <div className="flex items-center space-x-2 cursor-pointer">
            {adminPhoto ? (
              <img
                src={getImageUrl(adminPhoto, "/default-profile.jpg")}
                alt="Admin"
                className="w-8 h-8 object-cover rounded-full border-2 border-green-500"
              />
            ) : (
              <Avatar name={adminName} size="32" round />
            )}
            <span className="text-sm font-medium">{adminName}</span>
          </div>
          {isOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
              <ul className="py-2">
                <li
                  className="px-4 py-2 text-black hover:bg-gray-100 cursor-pointer"
                  onClick={() => navigate("/profile")}
                >
                  My Profile
                </li>
                <li
                  className="px-4 py-2 text-black hover:bg-gray-100 cursor-pointer"
                  onClick={() => navigate("/change-password")}
                >
                  Change Password
                </li>
                <li
                  className="px-4 py-2 text-black hover:bg-gray-100 cursor-pointer"
                  onClick={handleLogout}
                >
                  Log Out
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
