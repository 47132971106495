import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";
import { get, getWithParams } from "../../utils/fetchAPI";
import AdminTable from "./adminTable";

const Admins = () => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [userId, setUserId] = useState(null);

  const navigate = useNavigate();

  const fetchAdmins = async (page, limit) => {
    setLoading(true);
    try {
      const params = { page, limit };
      const response = await getWithParams("admin/admins", params);

      if (response?.status === 200) {
        setAdmins(response?.data?.data?.docs || []);
        setTotalRows(response?.data?.data?.totalDocs);
      } else {
        setAdmins([]);
      }
    } catch (err) {
      setError("Failed to fetch admins");
      localStorage.removeItem("user");
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchProfileDetails = async () => {
      try {
        const response = await get("/admin/admins/get-details");
        const data = response.data;

        if (data.success) {
          setUserId(data.data._id);
        } else {
          throw new Error("Failed to retrieve profile details");
        }
      } catch (error) {
        console.error("Error fetching profile details:", error);
        toast.error(error.message || "Failed to retrieve profile details.");
      }
    };

    fetchProfileDetails();
  }, []);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("user");
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }

    fetchAdmins(page, limit);
  }, [page, limit, navigate]);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "user" && !e.newValue) {
        navigate("/login");
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="w-full">
      <AdminTable
        admins={admins}
        totalRows={totalRows}
        page={page}
        limit={limit}
        setPage={setPage}
        setLimit={setLimit}
        userId={userId}
      />
    </div>
  );
};

export default Admins;
