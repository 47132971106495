import moment from "moment-timezone";
import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import JoditEditorComponent from "../../components/editor/JoditEditorComponent";
import Loader from "../../components/loader/Loader";
import { post } from "../../utils/fetchAPI";

const CreateEvent = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    title: "",
    subtitle: "",
    content: "",
    startDate: "",
    endDate: "",
    timezone: "",
    place: "",
    banner: null,
    gallery: [],
    thumbnail: null,
    status: true,
  });

  const timezones = moment.tz.names().map((tz) => ({ value: tz, label: tz }));

  const [imagePreviews, setImagePreviews] = useState({
    bannerPreview: null,
    thumbnailPreview: null,
    galleryPreviews: [],
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);

  const filteredTimezones = moment.tz
    .names()
    .filter((tz) => tz.toLowerCase().includes(searchTerm.toLowerCase()));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e, fieldName, previewField) => {
    const file = e.target.files[0];
    if (file) {
      setForm((prevState) => ({
        ...prevState,
        [fieldName]: file,
      }));

      const url = URL.createObjectURL(file);
      setImagePreviews((prevState) => ({
        ...prevState,
        [previewField]: url,
      }));
    }
  };

  const handleGalleryImageChange = (e) => {
    const files = Array.from(e.target.files);
    const newGallery = [];
    const newGalleryPreviews = [];

    files.forEach((file) => {
      const url = URL.createObjectURL(file);
      newGallery.push(file);
      newGalleryPreviews.push(url);
    });

    setForm((prevState) => ({
      ...prevState,
      gallery: [...prevState.gallery, ...newGallery],
    }));

    setImagePreviews((prevState) => ({
      ...prevState,
      galleryPreviews: [...prevState.galleryPreviews, ...newGalleryPreviews],
    }));

    e.target.value = null;
  };

  const removeGalleryImage = (index) => {
    setForm((prevState) => {
      const newGallery = [...prevState.gallery];
      newGallery.splice(index, 1);
      return {
        ...prevState,
        gallery: newGallery,
      };
    });

    setImagePreviews((prevState) => {
      const newGalleryPreviews = [...prevState.galleryPreviews];
      newGalleryPreviews.splice(index, 1);
      return {
        ...prevState,
        galleryPreviews: newGalleryPreviews,
      };
    });
  };

  const handleContentChange = (content) => {
    setForm((prevState) => ({
      ...prevState,
      content,
    }));
  };

  const handleStatusChange = () => {
    setForm((prevState) => ({
      ...prevState,
      status: !prevState.status,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (!form.content.trim()) {
      toast.error("Content is required.");
      setLoading(false);
      return;
    }

    if (!form.timezone) {
      toast.error("Time zone is required.");
      setLoading(false);
      return;
    }

    const formData = new FormData();

    const startDateISO = moment.tz(form.startDate, form.timezone).format();
    const endDateISO = moment.tz(form.endDate, form.timezone).format();

    Object.keys(form).forEach((key) => {
      if (key === "gallery") {
        form[key].forEach((file) => formData.append("gallery", file));
      } else if (key === "banner" || key === "thumbnail") {
        if (form[key]) formData.append(key, form[key]);
      } else if (key === "startDate") {
        formData.append("startDateTime", startDateISO);
      } else if (key === "endDate") {
        formData.append("endDateTime", endDateISO);
      } else if (key === "timezone") {
        formData.append("timezone", form[key]);
      } else {
        formData.append(key, form[key]);
      }
    });

    try {
      const response = await post("/admin/events", formData);
      console.log("response: ", response);
      if (response?.data?.statusCode === 200) {
        toast.success("Event created successfully!");

        setForm({
          title: "",
          subtitle: "",
          content: "",
          startDate: "",
          endDate: "",
          timezone: "",
          place: "",
          banner: null,
          gallery: [],
          thumbnail: null,
          status: false,
        });
        setImagePreviews({
          bannerPreview: null,
          thumbnailPreview: null,
          galleryPreviews: [],
        });

        navigate(`/events/view/`);
      } else {
        toast.error(
          response.error || `Error creating event. Please try again.`
        );
      }
    } catch (error) {
      toast.error("Error creating event. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Show loader while loading
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader />
      </div>
    );
  }

  return (
    <section className="p-1">
      <div className="py-1">
        {loading && (
          <div className="flex items-center justify-center min-h-screen">
            <Loader />
          </div>
        )}
        <div className="bg-white p-5 rounded shadow-md">
          <form onSubmit={handleSubmit}>
            <div className="mb-4 flex">
              <div className="flex-1 mr-2">
                <label className="block text-sm font-medium text-gray-700">
                  Title <span className="text-red-500">*</span>
                </label>
                <textarea
                  type="text"
                  name="title"
                  value={form.title}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded"
                  required
                />
              </div>
              <div className="flex-1 mx-2">
                <label className="block text-sm font-medium text-gray-700">
                  Subtitle
                </label>
                <textarea
                  name="subtitle"
                  value={form.subtitle}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded"
                />
              </div>
              <div className="flex-1 ml-2">
                <label className="block text-sm font-medium text-gray-700">
                  Start Date-Time <span className="text-red-500">*</span>
                </label>
                <input
                  type="datetime-local"
                  name="startDate"
                  value={form.startDate}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded"
                  required
                />
              </div>
              <div className="flex-1 ml-2">
                <label className="block text-sm font-medium text-gray-700">
                  End Date-Time <span className="text-red-500">*</span>
                </label>
                <input
                  type="datetime-local"
                  name="endDate"
                  value={form.endDate}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded"
                  required
                />
              </div>
            </div>
            <div className="mb-4 flex">
              <div className="flex-1 mr-2">
                <label className="block text-sm font-medium text-gray-700">
                  Time Zone <span className="text-red-500">*</span>
                </label>
                <Select
                  value={timezones.find((tz) => tz.value === form.timezone)}
                  onChange={(selectedOption) =>
                    setForm((prevState) => ({
                      ...prevState,
                      timezone: selectedOption.value,
                    }))
                  }
                  options={timezones}
                  className="mt-1"
                  placeholder="Select Time Zone"
                  isSearchable
                />
              </div>
              <div className="flex-1 ml-2">
                <label className="block text-sm font-medium text-gray-700">
                  Event Place <span className="text-red-500">*</span>
                </label>
                <input
                  name="place"
                  value={form.place}
                  onChange={handleInputChange}
                  className="w-full mt-1 p-2 border border-gray-300 rounded"
                  required
                />
              </div>
            </div>
            <div className="mb-4 grid grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label className="block text-sm font-medium text-gray-700">
                  Banner Image <span className="text-red-500">*</span>
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleFileChange(e, "banner", "bannerPreview")
                  }
                  className="w-full mt-1 p-2 border border-gray-300 rounded"
                  required
                />
                {imagePreviews.bannerPreview && (
                  <img
                    src={imagePreviews.bannerPreview}
                    alt="Banner Preview"
                    className="mt-2 h-64 w-auto object-contain"
                  />
                )}
              </div>
              <div className="flex flex-col">
                <label className="block text-sm font-medium text-gray-700">
                  Thumbnail Image <span className="text-red-500">*</span>
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleFileChange(e, "thumbnail", "thumbnailPreview")
                  }
                  className="w-full mt-1 p-2 border border-gray-300 rounded"
                  required
                />
                {imagePreviews.thumbnailPreview && (
                  <img
                    src={imagePreviews.thumbnailPreview}
                    alt="Thumbnail Preview"
                    className="mt-2 h-64 w-auto object-contain"
                  />
                )}
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Content <span className="text-red-500">*</span>
              </label>
              <div className="h-auto">
                <JoditEditorComponent
                  content={form.content}
                  onChange={handleContentChange}
                  required
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Add Gallery Images
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleGalleryImageChange}
                className="w-full mt-1 p-2 border border-gray-300 rounded"
                multiple
              />
            </div>
            <div className="mb-4">
              <div className="flex flex-wrap">
                {imagePreviews.galleryPreviews.map((preview, index) => (
                  <div key={index} className="relative mr-2 mb-2">
                    <img
                      src={preview}
                      alt={`Gallery Preview ${index}`}
                      className="h-32 w-auto object-cover rounded"
                    />
                    <button
                      type="button"
                      className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                      onClick={() => removeGalleryImage(index)}
                    >
                      <span className="text-xs">X</span>
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="mb-4 flex justify-between items-center">
              <label className="block text-sm font-medium text-gray-700"></label>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={form.status}
                  onChange={handleStatusChange}
                  className="hidden"
                  id="statusToggle"
                />
                <label
                  htmlFor="statusToggle"
                  className={`flex items-center cursor-pointer w-16 h-8 bg-gray-300 rounded-full transition duration-300 ease-in-out 
      ${form.status ? "bg-green-500" : "bg-red-500"}`}
                >
                  <span
                    className={`bg-white w-7 h-7 rounded-full shadow-md transform transition duration-300 ease-in-out 
        ${form.status ? "translate-x-8" : "translate-x-0"}`}
                  ></span>
                </label>
                <span className="ml-3 text-sm font-medium text-gray-700">
                  {form.status ? "Active" : "Inactive"}
                </span>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className={`mt-4 py-2 text-white bg-blue-600 rounded ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                style={{ width: "200px" }}
                disabled={loading}
              >
                {loading ? "Creating Event..." : "Create Event"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default CreateEvent;
